import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';
import './Reviews.css';

function Reviews() {
  return (
    <div className="center">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');
      </style>
      
      <CustomNav />
      <div className="review1">
        <div className="review1-elements">
          <p className="review1-text">"I did two sets of graduation pictures with Dina. One with my group of 11 friends and then the other with my sister. The pictures from both shoots turned out absolutely stunning!! Actually, was revisiting the pictures by coincidence today and my parents and I remain in love. <br></br><br></br>Not only were the photos amazing, the experience itself was too. I had so much fun with my sister and my friends and knew that I could trust Dina. She made sure we laughed AND got the shots we needed. Would highly recommend working with her." - Mia K.</p>
          <Image className="review1-image" src="https://picsbydina.s3.amazonaws.com/MiaGroupGrad90.jpg" />
        </div>
      </div>
      <div className="review2">
        <div className="review2-elements">
          <Image className="review2-image hide1-reviews" src="https://picsbydina.s3.amazonaws.com/LisaAndTim-22.jpg" />
          <p className="review2-text">"Such a sweet photographer! Very personable & makes you very comfortable during the shoot. Very responsive & quick turnaround." - Lisa L.</p>
          <Image className="review2-image hide2-reviews" src="https://picsbydina.s3.amazonaws.com/LisaAndTim-22.jpg" />
        </div>
      </div>
      <div className="review3">
        <div className="review3-elements">
          <p className="review3-text">"Aside from the amazing quality of the photos, you're getting the highest quality photographer in the game. I felt so comfortable and pretty when shooting with Dina - she knew all the best angles and poses and kept making me smile!" - Julia L. </p>
          <Image className="review3-image" src="https://picsbydina.s3.amazonaws.com/JuliaL.jpg" />
        </div>
      </div>
      <div className="review4">
        <div className="review4-elements">
          <Image className="review4-image hide1-reviews" src="https://picsbydina.s3.amazonaws.com/PSweaters.jpg" />
          <p className="review4-text">"Dina is the best photographer! She helped position us to make the cutest photos." - Christina M. <br></br><br></br> "Dina took the most beautiful pictures! She was flexible, fun, and extremely speedy with editing." - Madison J.</p>
          <Image className="review4-image hide2-reviews" src="https://picsbydina.s3.amazonaws.com/PSweaters.jpg" />
        </div>
      </div>
      <div className="review5">
        <div className="review5-elements">
          <p className="review5-text">"Dina is such a wonderful photographer to work with. I really like how her offerings covered such a variety of types of shoots; she photographed my professional headshots as well as a fun shoot with my friends! The photos turned out great both times and I have used her headshot on my LinkedIn since. <br></br><br></br> She is such a good communicator and makes everyone feel comfortable in front of the camera. I would recommend her to anyone looking for an easygoing photographer for any type of need!" - Gabby D.</p>
          <Image className="review5-image" src="https://picsbydina.s3.amazonaws.com/Gabby.jpg" />
        </div>
      </div>
      <div className="leave-review"><a target="_blank" href="https://forms.gle/ZvpXAeQZPvKZoJPAA" className="leave-review-size">LEAVE A REVIEW</a></div>
    </div>

  );
}

export default Reviews;
