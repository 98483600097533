import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image, Button } from 'react-bootstrap';

function Headshots() {
  return (
    <div>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots1.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots2.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots3.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots4.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots5.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots6.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots7.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots8.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots9.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots10.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots11.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots12.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots13.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots14.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Headshots/Headshots15.jpg" />
        </div>
        <div className="book-portfolio"><a target="_blank" href="https://calendly.com/dinaleyphotography/spring-23-headshots?month=2023-02" className="book-portfolio-size">BOOK HEADSHOTS</a></div>
      </div>
    </div>

  );
}

export default Headshots;
