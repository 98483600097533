import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Portfolio from './Portfolio';
import Headshots from './Headshots';
import Portraits from './Portraits';
import Couples from './Couples';
import Grad from './Grad';
import Events from './Events';
import Seniors from './Seniors';
import Reviews from './Reviews';
import Family from './Family';
import Weddings from './Weddings';
import Brands from './Brands';
import BookGrad from './BookGrad';
import BookCouples from './BookCouples';
import BookPortraits from './BookPortraits';
import BookSeniors from './BookSeniors';
import BookFamily from './BookFamily';
import BookWeddings from './BookWeddings';
import BookBrands from './BookBrands';
import BookMe from './BookMe';
import './App.css';

function App() {
  return (
    <Router>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&family=Puritan:wght@400;700&display=swap');
      </style>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/headshots" element={<Headshots />}></Route>
        <Route path="/portraits" element={<Portraits />}></Route>
        <Route path="/couples" element={<Couples />}></Route>
        <Route path="/grad" element={<Grad />}></Route>
        <Route path="/events" element={<Events />}></Route>
        <Route path="/seniors" element={<Seniors />}></Route>
        <Route path="/reviews" element={<Reviews />}></Route>
        <Route path="/family" element={<Family />}></Route>
        <Route path="/weddings" element={<Weddings />}></Route>
        <Route path="/brands" element={<Brands />}></Route>
        <Route path="/bookgrad" element={<BookGrad />}></Route>
        <Route path="/bookcouples" element={<BookCouples />}></Route>
        <Route path="/bookportraits" element={<BookPortraits />}></Route>
        <Route path="/bookseniors" element={<BookSeniors />}></Route>
        <Route path="/bookfamily" element={<BookFamily />}></Route>
        <Route path="/bookweddings" element={<BookWeddings />}></Route>
        <Route path="/bookbrands" element={<BookBrands />}></Route>
        <Route path="/bookme" element={<BookMe />}></Route>
      </Routes>
    </Router>
  );
}

export default App;