import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Couples() {

  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples1.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples2.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples3.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples4.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples5.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples6.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples7.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples8.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples9.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples10.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples11.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples12.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples13.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples14.JPG" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Couples/Couples15.JPG" />   
        </div>
        <div className="book-portfolio"><a href="/bookcouples" className="book-portfolio-size">BOOK COUPLES</a></div>
      </div>
    </div>
  );
}

export default Couples;