import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';
import './BookGrad.css';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function BookGrad() {

  const script = document.createElement("script");
  script.src = "https://assets.calendly.com/assets/external/widget.js";
  script.async = true;
  document.body.appendChild(script);

  return (

    <div className="sessions">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');
      </style>
      <CustomNav />
      <div>
      </div>
      
      <h2 className="interest-form">UPENN Class of 2024 Interest Form is closed</h2>
      <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
    </div>

  );
}

export default BookGrad;
