import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';
import './BookPortraits.css';
import { Form, Col, Button } from 'react-bootstrap';
import { React, useState } from 'react';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function BookFamily() {

  const initialFormData = Object.freeze({
    username: "",
    email: "",
    mobile: "",
    query: ""
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim()
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    alert(`Thank you for your inquiry! I will respond as soon as I can.`);
    const templateId = 'template_weesldp';
    const serviceID = "service_hth3eh5";
    sendFeedback(serviceID, templateId, { from_name: formData.name, mobile: formData.mobile, message_html: formData.query, email: formData.email })

    console.log(formData);
    };

    const sendFeedback = (serviceID, templateId, variables) => {
        window.emailjs.send(
            serviceID, templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!')
        })
            .catch(err => console.error('There has been an Error.', err))
    }
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portraitsession">
        <div className="portrait-elements">
          <Image className="bookportrait-image" src="https://picsbydina.s3.amazonaws.com/Family/Family.jpg" />
          <Image className="bookportrait-image portraithide" src="https://picsbydina.s3.amazonaws.com/Family/Family-4.jpg" />
          <Image className="bookportrait-image portraithide portraithide1" src="https://picsbydina.s3.amazonaws.com/Family/Family-2.jpg" />
          <div className="portrait-text">
            <div className="portrait-pricinginfo">
              <p className="portrait-price">250 and up</p>
              <p className="portrait-refund">includes a 25% non-refundable deposit</p>
              <hr></hr>
              <Form>
                <Form.Group as={Col} controlId="formGridName">
                  <Form.Control onChange={handleChange} name="name" type="name" placeholder="Enter Name (First and Last)" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Control onChange={handleChange} name="email" type="email" placeholder="Enter email"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formGridMobile">
                  <Form.Control onChange={handleChange} name="mobile" placeholder="Enter cell phone number" />
                </Form.Group>
                <Form.Group as={Col} id="formGridQuery">
                  <Form.Control onChange={handleChange} name="query" as="textarea" rows={3} placeholder="Let me know what your vision for the shoot is!"/>
                </Form.Group>

                <Button className="btn-custom" onClick={handleSubmit} variant="primary" type="submit">
    Submit Inquiry
</Button>
              </Form >
            </div>
          </div>
        </div>
      </div>
      <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
    </div>

  );
}

export default BookFamily;

