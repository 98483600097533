import './Portfolio.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import templogo from './temp_logo.png';
import Image from 'react-bootstrap/Image';
import CustomNav from './components/CustomNav';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function Portfolio() {
    return (
        <div>
            <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
            <CustomNav />
            <div className="categories">
            <div className="gradDiv">
                <a href="/grad">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/ZBTGrad516.jpg" />
                <div className="overlay">
                    <div className="text">GRAD</div>
                  </div>
                </a>
            </div>
            <div className="seniorDiv">
                <a href="/weddings">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-03.jpg" />
                <div className="overlay">
                    <div className="text">WEDDINGS</div>
                  </div>
                </a>
            </div>
            <div className="seniorDiv">
                <a href="/brands">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-05.jpg" />
                <div className="overlay">
                    <div className="text">BRANDS</div>
                  </div>
                </a>
            </div>
            <div className="couplesDiv">
                <a href="/couples">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/CameronAndNithya-149.jpg" />
                <div className="overlay">
                    <div className="text">COUPLES</div>
                  </div>
                </a>
            </div>
            <div className="portraitsDiv">
                <a href="/portraits">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Roene4_8Edited48.jpg" />
                <div className="overlay">
                    <div className="text">PORTRAITS</div>
                  </div>
                </a>
            </div>
            <div className="seniorDiv">
                <a href="/family">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Family/Family.jpg" />
                <div className="overlay">
                    <div className="text">FAMILY</div>
                  </div>
                </a>
            </div>
            <div className="seniorDiv">
                <a href="/seniors">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/MayaSeniorPost-1.jpg" />
                <div className="overlay">
                    <div className="text">SENIORS</div>
                  </div>
                </a>
            </div>
            <div className="eventsDiv">
                <a href="/events">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Beta-562.jpg" />
                <div className="overlay">
                    <div className="text">EVENTS</div>
                  </div>
                </a>
            </div>
            <div className="headshotsDiv">
                <a className="categorylink" href="/headshots">
                <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/PeterHeadshot8.jpg" />
                <div className="overlay">
                    <div className="text">HEADSHOTS</div>
                  </div>
                </a>
            </div>
        </div>
        <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
        </div>
    );
}

export default Portfolio;
