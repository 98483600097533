import './Nav.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../HorizontalLogo2.svg';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

function CustomNav() {
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    const handleClick = () => {
        navigate("/portfolio");
    }

    const [showBookMe, setBookMeShow] = useState(false);
    const showBookMeDropdown = (e) => {
        setBookMeShow(!showBookMe);
    }
    const hideBookMeDropdown = e => {
        setBookMeShow(false);
    }

    const handleBookMeClick = () => {
        navigate("/bookme");
    }

    return (
        <Navbar className="custom-navbar" fixed="top" expand="md">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
            </style>
            <Container>
                <Navbar.Brand href="/"><img className="logo" src={logo} alt="Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="custom-collapse" id="basic-navbar-nav">
                    <Nav className="links">
                    <NavDropdown
                            title="BOOK ME"
                            className="hide"
                            id="collasible-nav-dropdown"
                            show={showBookMe}
                            onMouseEnter={showBookMeDropdown}
                            onMouseLeave={hideBookMeDropdown}
                            onClick={handleBookMeClick}>
                            <NavDropdown.Item href="/bookgrad">GRAD</NavDropdown.Item>
                            <NavDropdown.Item href="/bookweddings">WEDDINGS</NavDropdown.Item>
                            <NavDropdown.Item href="/bookbrands">BRANDS</NavDropdown.Item>
                            <NavDropdown.Item href="/bookcouples">COUPLES</NavDropdown.Item>
                            <NavDropdown.Item href="/bookportraits">PORTRAITS</NavDropdown.Item>
                            <NavDropdown.Item href="/bookfamily">FAMILY</NavDropdown.Item>
                            <NavDropdown.Item href="/bookseniors">SENIORS</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown
                            title="PORTFOLIO"
                            className="hide"
                            id="collasible-nav-dropdown bookmedropdown"
                            show={show}
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}
                            onClick={handleClick}>
                            <NavDropdown.Item href="/grad">GRAD</NavDropdown.Item>
                            <NavDropdown.Item href="/weddings">WEDDINGS</NavDropdown.Item>
                            <NavDropdown.Item href="/brands">BRANDS</NavDropdown.Item>
                            <NavDropdown.Item href="/couples">COUPLES</NavDropdown.Item>
                            <NavDropdown.Item href="/portraits">PORTRAITS</NavDropdown.Item>
                            <NavDropdown.Item href="/family">FAMILY</NavDropdown.Item>
                            <NavDropdown.Item href="/seniors">SENIORS</NavDropdown.Item>
                            <NavDropdown.Item href="/events">EVENTS</NavDropdown.Item>
                            <NavDropdown.Item href="/headshots">HEADSHOTS</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link className="custom-color hide2" href="/bookme">BOOK ME</Nav.Link>
                        <Nav.Link className="custom-color hide2" href="/portfolio">PORTFOLIO</Nav.Link>
                        <Nav.Link className="custom-color" href="/reviews">REVIEWS</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNav;