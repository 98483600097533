import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Portraits() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits1.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits2.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits3.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits4.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits5.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits6.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits7.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits8.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits9.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits10.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits11.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Portraits/Portraits12.jpg" />
         
        </div>
        <div className="book-portfolio"><a href="/bookportraits" className="book-portfolio-size">BOOK PORTRAITS</a></div>

      </div>
    </div>
  );
}

export default Portraits;
