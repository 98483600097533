import './Home.css';
import Image from 'react-bootstrap/Image';
import CustomNav from './components/CustomNav';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function Home() {
  return (
    <div className="home">
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
        @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500&display=swap');
      </style>
      <CustomNav />
      <div className="home-images-center">
        <div className="home-images scrolling-wrapper">
          <Image className="home-page-image card" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-10.jpg" />
          <Image className="home-page-image card" src="https://picsbydina.s3.amazonaws.com/ZBT-20.jpg" />
          <Image className="home-page-image card" src="https://picsbydina.s3.amazonaws.com/Elena66.jpg" />
          <Image className="home-page-image card" src="https://picsbydina.s3.amazonaws.com/RoeneAndJesse-64.jpg" />
          <Image className="home-page-image card" src="https://picsbydina.s3.amazonaws.com/Mia&Talia11.jpg" />
        </div>
      </div>
      <div className="about-me">
        <div className="about-me-elements">
          <div className="about-me-text">
            <p className="about-me-dina">HI, I'M DINA!</p>
            <p> I fell in love with photography while taking a film photography class at the Pittsburgh Center for the Arts when I was 12 years old.
              Since then, my passion has continued to grow, and I've captured everything from couples and portraits to events and headshots!
              <br /><br />Through photography, I enjoy meeting new people and having new experiences, while capturing once-in-a-lifetime memories for my clients!
              Thank you for visiting my website, and please reach out if you have questions or would like to book a photoshoot.</p>
          </div>
          <Image className="home-page-image-me" src="https://picsbydina.s3.amazonaws.com/443A9126.jpg" />
        </div>
      </div>
      <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
    </div>
  );
}

export default Home;
