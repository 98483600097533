import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Weddings() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-01.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-07.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-03.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-04.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-05.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-21.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-02.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-08.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-06.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-09.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-13.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-14.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-15.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-27.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-22.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-16.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-17.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-18.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-19.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-20.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-24.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-25.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-26.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-28.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding-23.jpg" />

        </div>
        <div className="book-portfolio"><a href="/bookweddings" className="book-portfolio-size">BOOK WEDDINGS</a></div>

      </div>
    </div>
  );
}

export default Weddings;