import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Grad() {
  return (
    <div>
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Unbounded&display=swap');
      </style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/JojoAllegraCeceliaEmilianaGrad674.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/ZBTGrad532.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/ChloeJaneDanielleGrad62.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/AnchitaGroupGrad491.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/MadisonGroupGrad476.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/NafisaGroupGrad342.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/CatherineKolskiGroupGrad160.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/KaylaSkylaGrad247.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/KateSkylerGrad122.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/MoniqueHalleyMaxMarcGrad429.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/OTGrad189.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/MoniqueHalleyMaxMarcGrad190.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/ShyamaGroupGrad426.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/MoniqueGrad65.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Grad/AlexaAndDom.jpg" />
        </div>
        <div className="book-portfolio"><a href="/bookgrad" className="book-portfolio-size">BOOK GRAD</a></div>
      </div>
    </div>
  );
}

export default Grad;
