import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Seniors() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/443A3067.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Elizabeth10.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/MayaSeniorPost-2.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Jess46.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Jess129.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Elizabeth79.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Jess93.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/Elizabeth62.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Seniors/MayaSeniorPost-3.jpg" />
        </div>
        <div className="book-portfolio"><a href="/bookseniors" className="book-portfolio-size">BOOK SENIORS</a></div>
      </div>
    </div>
  );
}

export default Seniors;
