import './Events.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function Events() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="events-content">
        <div className="events-images">
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/Beta-13.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/Beta-316.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/Beta-85.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/PhiDeltFormalS21316.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/PhiDeltFormalS2171.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/PhiDeltOct6-2.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/SK-603.jpg" />
          <Image className="events-image" src="https://picsbydina.s3.amazonaws.com/Events/MaskAndWigFormalFall2021-408.jpg" />
        </div>
      </div>
      <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
    </div>
  );
}

export default Events;
