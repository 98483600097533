import './BookMe.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import templogo from './temp_logo.png';
import Image from 'react-bootstrap/Image';
import CustomNav from './components/CustomNav';
import './BookCouples.css';
import { Form, Col, Button } from 'react-bootstrap';
import { React, useState } from 'react';
import ig from './icons8-instagram-96.png';
import tt from './icons8-tiktok-100.png';
import pin from './icons8-pinterest-60.png';

function Portfolio() {
  const initialFormData = Object.freeze({
    username: "",
    email: "",
    mobile: "",
    query: ""
  });

  const [formData, updateFormData] = useState(initialFormData);

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      [e.target.name]: e.target.value.trim()
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    alert(`Thank you for your inquiry! I will respond as soon as I can.`);
    const templateId = 'template_r3m0z2j';
    const serviceID = "service_hth3eh5";
    sendFeedback(serviceID, templateId, { from_name: formData.name, mobile: formData.mobile, message_html: formData.query, email: formData.email, date: formData.date, location: formData.location, instagram: formData.instagram })

    console.log(formData);
  };

  const sendFeedback = (serviceID, templateId, variables) => {
    window.emailjs.send(
      serviceID, templateId,
      variables
    ).then(res => {
      console.log('Email successfully sent!')
    })
      .catch(err => console.error('There has been an Error.', err))
  }
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="categories-bookme">
      <div className="gradDiv-bookme">
          <a href="/bookgrad">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/BookMe/ZBTGrad412.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK GRAD</div>
            </div>
          </a>
        </div>
        <div className="seniorDiv-bookme">
          <a href="/bookweddings">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Weddings/Wedding.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK WEDDINGS</div>
            </div>
          </a>
        </div>
        <div className="seniorDiv-bookme">
          <a href="/bookbrands">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-16.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK BRANDS</div>
            </div>
          </a>
        </div>
        <div className="couplesDiv-bookme">
          <a href="/bookcouples">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/BookMe/ZoeAndChristian-57.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK COUPLES</div>
            </div>
          </a>
        </div>
        <div className="portraitsDiv-bookme">
          <a href="/bookportraits">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/BookMe/IMG_0101.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK PORTRAITS</div>
            </div>
          </a>
        </div>
        <div className="seniorDiv-bookme">
          <a href="/bookfamily">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/Family/Family-2.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK FAMILY</div>
            </div>
          </a>
        </div>
        <div className="seniorDiv-bookme">
          <a href="/bookseniors">
            <Image className="portfolio-page-image-1" src="https://picsbydina.s3.amazonaws.com/BookMe/MayaSenior-31.jpg" />
            <div className="overlay">
              <div className="book-me-text">BOOK SENIORS</div>
            </div>
          </a>
        </div>
        <div className="eventsDiv-bookme">
          <Form className="bookme-form">
            <h2 className="inquiryform-header">INQUIRY FORM</h2>
            <p className="inquiryform-desc">If you're looking for something else, please reach out via email (picsbydina@gmail.com) or contact me through this form!</p>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Control onChange={handleChange} name="name" type="name" placeholder="Enter Name (First and Last)" />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridEmail">
              <Form.Control onChange={handleChange} name="email" type="email" placeholder="Enter email"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridIG">
              <Form.Control onChange={handleChange} name="instagram" type="text" placeholder="Enter Instagram handle"
              />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridMobile">
              <Form.Control onChange={handleChange} name="mobile" placeholder="Enter cell phone number" />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Control onChange={handleChange} name="location" type="text" placeholder="Shoot location" />
            </Form.Group>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Control onChange={handleChange} name="date" type="text" placeholder="Date range" />
            </Form.Group>
            <Form.Group as={Col} id="formGridQuery">
              <Form.Control onChange={handleChange} name="query" as="textarea" rows={6} placeholder="Let me know what kind of session you're looking for and what your vision for the shoot is!" />
            </Form.Group>

            <Button className="btn-custom" onClick={handleSubmit} variant="primary" type="submit">
              Submit Inquiry
            </Button>
          </Form >
        </div>
      </div>
      <div className="home-links">
        <div className="logos">
          Designed and developed by Dina Leyzarovich
        </div>
        <div className="center">
          <a target="_blank" href="https://www.tiktok.com/@pics_by_dina?_t=8ZTYGQVV2tO&_r=1"><img className="ttlogo" src={tt}></img></a>
          <a target="_blank" href="https://instagram.com/picsbydina?igshid=Zjc2ZTc4Nzk="><img className="iglogo" src={ig}></img></a>
          <a target="_blank" href="https://pin.it/3O0u5QG"><img className="ttlogo" src={pin}></img></a>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
