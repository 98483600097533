import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Brands() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-01.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-02.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-03.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-05.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-04.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-10.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-11.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-06.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-07.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-09.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-16.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-08.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-12.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-13.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-17.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-18.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-19.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-20.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-14.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-15.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-27.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-23.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-21.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-22.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-24.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-25.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-26.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-28.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-29.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-30.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-32.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-35.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-31.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-33.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-34.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-37.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-38.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-39.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-40.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-41.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-42.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-44.jpg" />
          <Image className="oasis-image" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-45.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-43.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-46.jpg" />
          <Image className="oasis-image-wide" src="https://picsbydina.s3.amazonaws.com/Brands/Oasis-48.jpg" />
        </div>
        <div className="book-portfolio"><a href="/bookbrands" className="book-portfolio-size">BOOK BRANDS</a></div>

      </div>
    </div>
  );
}

export default Brands;