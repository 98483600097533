import './Portfolio-Page.css'
import CustomNav from './components/CustomNav';
import { Image } from 'react-bootstrap';

function Family() {
  return (
    <div>
      <style>@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');</style>
      <CustomNav />
      <div className="portfolio-content">
        <div className="portfolio-images">
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Family/Family-1.jpg" />
          <Image className="portfolio-image-wide" src="https://picsbydina.s3.amazonaws.com/Family/Family-5.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Family/Family-3.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Family/Family-4.jpg" />
          <Image className="portfolio-image" src="https://picsbydina.s3.amazonaws.com/Family/Family-2.jpg" />

         
        </div>
        <div className="book-portfolio"><a href="/bookfamily" className="book-portfolio-size">BOOK FAMILY</a></div>

      </div>
    </div>
  );
}

export default Family;